function ModuleLoader() {
    this._bootstrapFuncs = [];
}

ModuleLoader.prototype.bootstrap = function(config) {
    this._bootstrapFuncs.forEach(function(bootstrapFunc) {
        bootstrapFunc(config);
    });
};

ModuleLoader.prototype.register = function(moduleBootstrapFunc) {
    if (this._bootstrapFuncs.indexOf(moduleBootstrapFunc) === -1) {
        this._bootstrapFuncs.push(moduleBootstrapFunc);
    }
};

if (window.configuratorModuleLoader == null) {
    window.configuratorModuleLoader = new ModuleLoader();
}

export default window.configuratorModuleLoader;