import BaseNetworkManager from 'core/BaseNetworkManager';
import { NetworkManager } from 'van-beek-framework/networking';

class ProjectNetworkManager extends BaseNetworkManager {

    _executeRequest(method, params, callback, returnBoolean = false) {
        var params = NetworkManager.getParameters(method, params);

        if (callback == null) {
            callback = function () { };
        }

        return NetworkManager.executeRequest(params, (data, error) => {
            callback(returnBoolean ? error == null : data, error);
        });
    }

}

export default new ProjectNetworkManager();