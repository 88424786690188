import BaseController from 'abstract/BaseController';
import { PopupButton, PopupIcon, PromptPopup, LoadingPopup, ConfirmPopup } from 'van-beek-framework/components/ui/popups';
import ConfiguratorNetworkManager from 'core/ConfiguratorNetworkManager';
import { Configuration, ConfigurationController, ConfigurationView } from 'entities/configuration';
import { QuotationLine, QuotationLineView } from 'entities/quotation-line';
import { ProjectItemController } from 'entities/project-item';

export default class ProjectController extends BaseController {
    static _configurationController = new ConfigurationController();
    static _quotationLineController = new ProjectItemController();

    subscribeTo(projectView) {
        super.subscribeTo(projectView);
        projectView.itemList.delegate = this;
    }

    unsubscribeFrom(projectView) {
        super.unsubscribeFrom(projectView);
        projectView.itemList.delegate = null;
    }

    itemViewForList(_, item) {
        let view = null;

        if (item instanceof Configuration) {
            view = new ConfigurationView();
            view.subscribeTo(item);
            this.constructor._configurationController.subscribeTo(view);
        } else if (item instanceof QuotationLine) {
            view = new QuotationLineView();
            view.subscribeTo(item);
            this.constructor._quotationLineController.subscribeTo(view);
        }

        return view;
    }

    onRenameProject(_, projectId, currentName) {
        PromptPopup.show({
            title: "project.action-rename.popup-new-name.title".localized,
            //message: "project.action-rename.popup-new-name.message".localized,
            placeholder: "project.action-rename.popup-new-name.input-name".localized,
            value: currentName,
            confirmButton: PopupButton.neutral("project.action-rename.popup-new-name.button-confirm".localized)       
        }).then(data => {
            if (data.value.length <= 0) {
                return;
            }

            LoadingPopup.show();

            ConfiguratorNetworkManager.renameProject(projectId, data.value, (_, error) => {
                LoadingPopup.hide();

                if (error != null) {
                    this._showGenericError(error);
                }
            });
        }).catch(() => {});
    }

    onDeleteProject(view, projectId) {
        ConfirmPopup.show({
            title: "project.action-delete.popup-confirm.title".localized,
            message: "project.action-delete.popup-confirm.message".localized.fillPlaceholders(view.name),
            icon: PopupIcon.trash(),
            confirmButton: PopupButton.destructive("project.action-delete.popup-confirm.button-confirm".localized)
        }).then(() => {
            LoadingPopup.show();

            ConfiguratorNetworkManager.deleteProject(projectId, (_, error) => {
                LoadingPopup.hide();

                if (error != null) {
                    this._showGenericError(error);
                }
            });
        }).catch(() => {});
    }

    onDuplicateProject(_, projectId, currentName) {
        PromptPopup.show({
            title: "project.action-duplicate.popup-name-copy.title".localized,
            message: "project.action-duplicate.popup-name-copy.message".localized,
            placeholder: "project.action-duplicate.popup-name-copy.input-name".localized,
            value: "project.action-duplicate.popup-name-copy.input-name-default".localized.fillPlaceholders(currentName),
            confirmButton: PopupButton.neutral("project.action-duplicate.popup-name-copy.button-confirm".localized)
        }).then(data => {
            if (data.value <= 0) {
                return;
            }

            LoadingPopup.show();

            ConfiguratorNetworkManager.duplicateProject(projectId, data.value, (newProjectData, error) => {
                LoadingPopup.hide();

                if (error != null) {
                    this._showGenericError(error);
                } else {
                    this.onProjectDuplicated(newProjectData);
                }
            });
        }).catch(() => {});
    }

    onProjectDuplicated(newProjectData) {}
}