import { EventEmitter } from 'van-beek-framework/events';

export default class BaseNetworkManager {

    //Project events
    addProjectEvent = new EventEmitter(this);
    deleteProjectEvent = new EventEmitter(this);
    requestQuotationEvent = new EventEmitter(this);
    renameProjectEvent = new EventEmitter(this);
    duplicateProjectEvent = new EventEmitter(this);
    fetchProjectsEvent = new EventEmitter(this);
    fetchProjectEvent = new EventEmitter(this);
    reviseProjectEvent = new EventEmitter(this);
    updateProjectCommentEvent = new EventEmitter(this);

    //Configuration events
    addConfigurationEvent = new EventEmitter(this);
    deleteConfigurationEvent = new EventEmitter(this);
    renameConfigurationEvent = new EventEmitter(this);
    duplicateConfigurationEvent = new EventEmitter(this);
    updateConfigurationQuantityEvent = new EventEmitter(this);
    updateConfigurationCommentEvent = new EventEmitter(this);
    updateConfigurationAdditionalSpecificationsEvent = new EventEmitter(this);

    _configurationQuantityRequests = {};

    _executeRequest(method, params, callback, returnBoolean = false) {
        throw Error("_executeRequest is abstract and needs to be overridden!");
    }

    getUserInfo(callback) {
        if (callback == null) {
            callback = function() { };
        }

        this._executeRequest("getuserinfo", {}, (data, error) => {
            if (error != null || data == null || data.length <= 0) {
                callback(data, error);
                return;
            }

            callback(data[0], error);
        });
    }

    sendPasswordResetLink(email, callback) {
        if (callback == null) {
            callback = function() { };
        }

        this._executeRequest("sendpasswordresetlink", { username: email }, (data, error) => {
            callback(error == null, error);
        });
    }

    changePassword(oldPassword, newPassword) {
        if (callback == null) {
            callback = function() { };
        }

        this._executeRequest("changepassword", { oldpassword: oldPassword, newpassword: newPassword }, (data, error) => {
            callback(error == null, error);
        });
    }

    resetPassword(token, newPassword) {
        if (callback == null) {
            callback = function() { };
        }

        this._executeRequest("changepassword", { directlink: token, newpassword: newPassword }, (data, error) => {
            callback(error == null, error);
        });
    }

    getProjects(callback) {
        if (callback == null) {
            callback = function () { };
        }

        this._executeRequest("osaShoppingList.contents", { includetrash: true }, (data, error) => {
            if (error == null) {
                this.fetchProjectsEvent.notify(data);
            }

            callback(data, error);
        });
    }

    getProject(projectId, callback) {
        if (callback == null) {
            callback = function () { };
        }

        this._executeRequest("osaShoppingList.contents", { shoppinglist: projectId }, (data, error) => {
            if (error != null || data == null || data.length <= 0) {
                callback(data, error);
                return;
            }

            var projectData = data[0];

            this.fetchProjectEvent.notify(projectData);
            callback(projectData, error);
        });
    }

    addProject(name, callback) {
        if (callback == null) {
            callback = function () { };
        }

        this._executeRequest("osaShoppingList.insert", { sl_Name: name }, (data, error) => {
            if (error != null || data == null) {
                callback(data, error);
                return;
            }

            var projectData = data[0];
            this.addProjectEvent.notify(projectData);
            callback(projectData);
        });
    }

    deleteProject(projectId, callback) {
        if (callback == null) {
            callback = function () { };
        }

        this._executeRequest("osaShoppingList.delete", { sl_No: projectId }, (data, error) => {
            if (error == null) {
                this.deleteProjectEvent.notify(projectId);
            }

            callback(error == null, error);
        });
    }

    requestQuotation(projectId, remark, callback) {
        if (callback == null) {
            callback = function () { };
        }

        this._executeRequest("osaShoppingList.createquote", { sl_No: projectId, requestremark: remark }, (data, error) => {
            if (error == null) {
                this.requestQuotationEvent.notify(projectId, data[0]);
            }

            callback(data, error);
        });
    }

    renameProject(projectId, newName, callback) {
        if (callback == null) {
            callback = function () { };
        }

        this._executeRequest("osaShoppingList.update", { sl_No: projectId, sl_Name: newName }, (data, error) => {
            if (error == null) {
                this.renameProjectEvent.notify(projectId, newName);
            }

            callback(error == null, error);
        });
    }

    duplicateProject(projectId, name, callback) {
        if (callback == null) {
            callback = function () { };
        }

        this._executeRequest("osaShoppingList.insert", { copyFrom: projectId, sl_Name: name }, (data, error) => {
            if (error != null || data == null) {
                callback(data, error);
                return;
            }

            var projectData = data[0];
            this.duplicateProjectEvent.notify(projectData);
            callback(projectData);
        });
    }

    reviseQuotation(projectId, callback) {
        if (callback == null) {
            callback = function () { };
        }

        this._executeRequest("osaShoppingList.revisequoterequest", { sl_No: projectId }, (data, error) => {
            if (error != null || data == null) {
                callback(data, error);
                return;
            }

            this.reviseProjectEvent.notify(projectId, data[0]);
            callback(data, error);
        });
    }

    duplicateConfiguration(configurationId, configurationUuid, name, callback) {
        if (callback == null) {
            callback = function () { };
        }

        this._executeRequest("omgComponent.duplicate", { configurationid: configurationUuid, orc_Name: name }, (data, error) => {
            if (error != null || data == null) {
                callback(data, error);
                return;
            }

            var configurationData = data[0];
            this.duplicateConfigurationEvent.notify(configurationId, configurationData);
            callback(configurationData);
        });
    }

    updateConfigurationQuantity(configurationId, quantity, callback) {
        if (callback == null) {
            callback = function () { };
        }

        if (this._configurationQuantityRequests[configurationId] != null) {
            this._configurationQuantityRequests[configurationId].abort();
        }

        this._configurationQuantityRequests[configurationId] = this._executeRequest("osaShoppingList.updateline", { orc_No: configurationId, orc_Quantity: quantity }, (data, error) => {
            if (error == null) {
                this.updateConfigurationQuantityEvent.notify(configurationId, quantity);
            }

            callback(error == null, error);
        });
    }

    updateProjectComment(projectId, newComment, callback) {
        if (callback == null) {
            callback = function() { };
        }

        // this.updateProjectCommentEvent.notify(projectId, newComment);
        // callback(true, null);

        this._executeRequest("osaShoppingList.update", { sl_No: projectId, sl_Remark: newComment }, (data, error) => {
            if (error == null) {
                this.updateProjectCommentEvent.notify(projectId, newComment);
            }

            callback(error == null, error);
        });
    }

    updateConfigurationComment(configurationId, configurationUuid, newComment, callback) {
        if (callback == null) {
            callback = function() { };
        }

        //this.updateConfigurationCommentEvent.notify(configurationId, newComment);

        this._executeRequest("omgComponent.updateconfigurationremark", { configurationid: configurationUuid, orc_remark: newComment }, (data, error) => {
            if (error == null) {
                this.updateConfigurationCommentEvent.notify(configurationId, newComment);
            }

            callback(error == null, error);
        });
    }

    updateConfigurationAdditionalSpecifications(configurationId, configurationUuid, newAdditionalSpecifications, callback) {
        if (callback == null) {
            callback = function() { };
        }

        this._executeRequest("omgComponent.updateconfigurationremark", { configurationid: configurationUuid, orc_AdditionalSpecifications: newAdditionalSpecifications }, (data, error) => {
            if (error == null) {
                this.updateConfigurationAdditionalSpecificationsEvent.notify(configurationId, newAdditionalSpecifications);
            }

            callback(error == null, error);
        });
    }

    renameConfiguration(configurationId, configurationUuid, newName, callback) {
        if (callback == null) {
            callback = function () { };
        }

        this._executeRequest("omgComponent.renameconfiguration", { configurationid: configurationUuid, name: newName }, (data, error) => {
            if (error == null) {
                this.renameConfigurationEvent.notify(configurationId, newName);
            }

            callback(error == null, error);
        });
    }

    deleteConfiguration(configurationId, callback) {
        if (callback == null) {
            callback = function () { };
        }

        this._executeRequest("omgComponent.delete", { orc_No: configurationId }, (data, error) => {
            if (error == null) {
                this.deleteConfigurationEvent.notify(configurationId);
            }

            callback(error == null, error);
        });
    }

    closeConfiguration(configurationId, callback) {
        if (callback == null) {
            callback = function () { };
        }

        this._executeRequest("omgComponent.closeconfiguration", { configurationid: configurationId }, () => {
            callback();
        });
    }

}