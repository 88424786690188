import { ProjectItemView } from 'entities/project-item';

export default class QuotationLineView extends ProjectItemView {
    subscribeTo(quotationLine) {
        super.subscribeTo(quotationLine);

        this.stateChangeSubscription = quotationLine.quotationStateChangedEvent.subscribe(this.onQuotationStateChanged.bind(this));
        this.onQuotationStateChanged(quotationLine);
    }

    unsubscribeFrom(quotationLine) {
        super.unsubscribeFrom(quotationLine);

        this.stateChangeSubscription.unsubscribe();
    }

    onQuotationStateChanged(quotationLine) {
        if (!quotationLine.quotationReady) {
            this.$propertyWrapper.addClass("vbf-hidden");
        } else {
            this.$propertyWrapper.removeClass("vbf-hidden");
        }
    }
}