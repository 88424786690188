import { EventEmitter } from 'van-beek-framework/events';
import { FormatPrice } from 'van-beek-framework/core/Utilities';

export default (superclass = class{}) => class ProjectItem extends superclass {

    _id = null;
    _name = "";
    _comment = "";
    _additionalSpecifications = "";
    _price = null;
    _modelDocumentId = null;
    _modelDocumentOutdated = false;
    _quantity = 1;
    _project = null;
    _canExecute = false;

    idChangedEvent = new EventEmitter(this);
    nameChangedEvent = new EventEmitter(this);
    commentChangedEvent = new EventEmitter(this);
    additionalSpecificationsChangedEvent = new EventEmitter(this);
    priceChangedEvent = new EventEmitter(this);
    modelDocumentChangedEvent = new EventEmitter(this);
    modelDocumentOutdatedEvent = new EventEmitter(this);
    quantityChangedEvent = new EventEmitter(this);

    constructor(id, name, price, quantity, project) {
        super();

        this._id = id || null;
        this._name = name || "";
        this._price = price || 0;
        this._quantity = quantity || 1;
        this._project = project || null;
    }
    
    get id() {
        return this._id;
    }

    set id(newId) {
        this._id = newId;
        this.idChangedEvent.notify(this.id);
    }

    get name() {
        return this._name != null && this._name.length > 0 ? this._name : "Item";
    }

    set name(newName) {
        this._name = newName;
        this.nameChangedEvent.notify(this.name);
    }

    get comment() {
        return this._comment;
    }

    set comment(newComment) {
        this._comment = newComment;
        this.commentChangedEvent.notify(this.comment);
    }

    get additionalSpecifications() {
        return this._additionalSpecifications;
    }

    set additionalSpecifications(newValue) {
        if (newValue === this.additionalSpecifications) {
            return;
        }

        this._additionalSpecifications = newValue;
        this.additionalSpecificationsChangedEvent.notify(this.additionalSpecifications);
    }

    get price() {
        return this._price;
    }

    get totalPrice() {
        return this.price * this.quantity;
    }

    get formattedTotalPrice() {
        return FormatPrice(this.totalPrice);
    }

    get formattedPrice() {
        return FormatPrice(this.price);
    }

    set price(newPrice) {
        this._price = newPrice;
        this.priceChangedEvent.notify(this.price, this.formattedPrice);
    }

    get modelDocumentId() {
        return this._modelDocumentId;
    }

    get modelDocumentOutdated() {
        return this._modelDocumentOutdated;
    }

    set modelDocumentOutdated(newValue) {
        if (newValue === this.modelDocumentOutdated) {
            return;
        }

        this._modelDocumentOutdated = newValue;
        this.modelDocumentOutdatedEvent.notify(this.modelDocumentOutdated);
    }

    get hasModel() {
        return this.modelDocumentId != null;
    }

    set modelDocumentId(newValue) {
        if (newValue <= 0) {
            return;
        }
        
        this._modelDocumentId = newValue;
        this.modelDocumentChangedEvent.notify(this.modelDocumentId);
    }

    get quantity() {
        return this._quantity;
    }

    set quantity(newValue) {
        if (newValue <= 0 || newValue > 99) {
            return;
        }

        this._quantity = newValue;
        this.quantityChangedEvent.notify(newValue);
        this.priceChangedEvent.notify(this.price, this.formattedPrice);
    }

    get hasProject() {
        return this.project != null;
    }

    get project() {
        return this._project;
    }

    set project(newProject) {
        this._project = newProject;
    }

    set canExecute(newValue) {
        if (newValue === this._canExecute) {
            return;
        }

        this._canExecute = newValue;
        this.modelDocumentChangedEvent.notify(this.modelDocumentId);
    }

    get canExecute() {
        return this._canExecute;
    }
}