import './CadViewer.css';
import { BaseView } from 'van-beek-framework/abstract';
import cadViewerCss from '!!raw-loader!./CadViewerIframe.css';
import srcDoc from 'srcdoc-polyfill/srcdoc-polyfill';

export default class CadViewer extends BaseView {
    constructor(inline = false) {
        super($(`<div class="cad-viewer__wrapper"></div>`));
        this._inline = inline;

        if (this._inline) {
            this._elem = this.$iframe;
            this.$iframe.addClass("cad-viewer-inline");
        }

        this._setupEventsAfterInline();
    }

    _populate() {
        super._populate();

        this.$background = $('<div class="cad-viewer__background"></div>');
        this.$iframe = $('<iframe class="cad-viewer__iframe"></iframe>');

        this.$elem.append(this.$background);
        this.$elem.append(this.$iframe);
    }

    _setupEventsAfterInline() {
        if (!this._inline) {
            this.$background.on("click", (e) => {
                e.stopPropagation();
                this.hide();
            });
        }

        this.$iframe.on('mouseenter', event => {
            $("body").css("overflow", "hidden");
        });

        this.$iframe.on('mouseleave', event => {
            $("body").css("overflow", "");
        });
    }
    
    show(document, overrideTitle = null) {
        let script = `<script type="text/javascript">$(document).ready(function() {`;

        if (!this._inline) {
            script += `$(".edrawings-hud-bar-content").append('<div id="edrawings-button-exit" class="edrawings-hud-button edrawings-hud-icon">X</div>');
                    $("#edrawings-button-exit").on('click', function() {
                        $(".cad-viewer__background", parent.document.body).trigger("click");
                    });`;
        }

        if (overrideTitle != null) {
            script += `$("#edrawings-filename-text").text("${overrideTitle}");`;
        }

        script += `}); </script>`;

        //Inject custom header code
        document = document.replace("</head>", `<style>${cadViewerCss}</style></head>`);
        document = document.replace("</body>", `${script}</body>`);
        srcDoc.set(this.$iframe[0], document);

        if (!this._inline) {
            $("body").append(this.$elem);
            this.$elem.addClass("cad-viewer__wrapper-active");
        }
    }

    hide() {
        if (this._inline) {
            return;
        }

        this.$elem.removeClass("cad-viewer__wrapper-active");
        setTimeout(() => {
            this.$iframe.attr("srcdoc", "");
            this.$elem.detach();
        }, 500);
    }
}