import "css/ProjectView.scss";

import { BaseView } from 'van-beek-framework/abstract';
import { ListView } from 'van-beek-framework/components/lists';

export default class ProjectView extends BaseView {

    _id = null;
    _name = null;

    get id() {
        return this._id;
    }

    get name() {
        return this._name;
    }

    constructor() {
        super($('<div class="configuration-basket"></div>'));
    }

    /**
     * Populates the list element with children.
     */
    _populate() {
        this.$header = $('<div class="configuration-basket__header"></div>');
        this.$title = $('<div class="configuration-basket__title"></div>');
        this.$nameTitleText = $('<span class="configuration-basket__title-name"></span>');
        this.$quotationTitleText = $('<span class="configuration-basket__title-quotation"></span>');

        this.$title.append(this.$nameTitleText);
        this.$title.append(this.$quotationTitleText);

        this.$header.append(this.$title);

        this.$contentWrapper = $('<div class="configuration-basket__content"></div>');
        this.$configurations = $('<div class="configuration-basket__configurations">');
        this.itemList = new ListView(this.$configurations);

        this.$infoWrapper = $('<div class="configuration-basket__info-wrapper"></div>');
        this.$subTotalWrapper = $(`<div class="configuration-basket__subtotal"><span>${"project.label-price".localized}</span></div>`);
        this.$subTotal = $('<span></span>');

        this.$subTotalWrapper.append(this.$subTotal);
        this.$infoWrapper.append(this.$subTotalWrapper);
        this.$contentWrapper.append(this.$configurations);
        this.$contentWrapper.append(this.$infoWrapper);

        this.$elem.append(this.$header);
        this.$elem.append(this.$contentWrapper);
    }

    subscribeTo(project) {
        this._idSubscription = project.idChangedEvent.subscribe(this.onIdChanged.bind(this));
        this._nameSubscription = project.nameChangedEvent.subscribe(this.onNameChanged.bind(this));
        this._priceSubscription = project.priceChangedEvent.subscribe(this.onPriceChanged.bind(this));
        this._deletionStateSubscription = project.deletionStateChangedEvent.subscribe(this.onDeletionStateChanged.bind(this));
        this._quotationStateSubscription = project.quotationReferenceChangedEvent.subscribe(this.onQuotationReferenceChanged.bind(this));

        this.itemList.subscribeTo(project.items);

        this.onIdChanged(project);
        this.onNameChanged(project);
        this.onPriceChanged(project);
        this.onQuotationReferenceChanged(project);
        this.onDeletionStateChanged(project);
    }

    unsubscribeFrom(project) {
        this._idSubscription.unsubscribe();
        this._nameSubscription.unsubscribe();
        this._priceSubscription.unsubscribe();
        this._deletionStateSubscription.unsubscribe();
        this._quotationStateSubscription.unsubscribe();

        this.itemList.unsubscribeFrom(project.items);
    }

    onIdChanged(project) {
        this._id = project.id;
        this.$elem.attr("data-id", project.id);
    }

    onNameChanged(project) {
        this._name = project.name;
        this.$nameTitleText.text(project.name);
        this.$quotationTitleText.html(project.quotationTitle);
    }

    onPriceChanged(project) {
        this.$subTotal.html(project.formattedPrice);
    }

    onDeletionStateChanged(project) {
        this._setReadOnlyState(!project.isActive);
    }

    onQuotationReferenceChanged(project) {
        this._setReadOnlyState(!project.isActive);

        if (project.hasActiveQuotation && !project.quotationReady) {
            this.$infoWrapper.addClass("vbf-hidden");
        } else {
            this.$infoWrapper.removeClass("vbf-hidden");
        }
    }

    _setReadOnlyState(isReadOnly) {
        if (isReadOnly) {
            this.$elem.addClass("vbf-readonly");
        } else {
            this.$elem.removeClass("vbf-readonly");
        }
    }
}