import { BaseController as VbfBaseController } from 'van-beek-framework/abstract';
import { NetworkError } from 'van-beek-framework/error-reporting';
import { ErrorPopup } from 'van-beek-framework/components/ui/popups';

export default class BaseController extends VbfBaseController {
    _showGenericError(error) {
        let errorData = {
            title: "generic.popup-error.title".localized,
            message: "generic.popup-error.message".localized
        };
    
        if (error instanceof NetworkError) {
            errorData.title = "generic.popup-error-network.title".localized;
            errorData.message = "generic.popup-error-network.message".localized;
        }
    
        return ErrorPopup.show(errorData);
    }
}