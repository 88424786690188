import { EventEmitter } from 'van-beek-framework/events';
import { SortedList } from 'van-beek-framework/components/lists';
import { Codable } from 'van-beek-framework/abstract';
import { EncodeUrlId, FormatPrice } from 'van-beek-framework/core/Utilities';
import { Configuration } from 'entities/configuration';
import { QuotationLine } from 'entities/quotation-line';

export default class Project extends Codable {

    static _itemCompareFunc = function (a, b) {
        var textA = a.name.toLowerCase(),
            textB = b.name.toLowerCase(),
            idA = a.id,
            idB = b.id;

        if (textA < textB) {
            return -1;
        } else if (textA > textB) {
            return 1;
        } else if (idA < idB) {
            return -1;
        } else if (idA > idB) {
            return 1;
        } else {
            return 0;
        }
    };

    _id = null;
    _name = "";
    _comment = "";
    _quotationReference = null;
    _quotationState = null;
    _quotationDocumentId = null;
    _quotationPrice = null;
    _deleted = false;
    _configurations = [];
    _quotationLines = [];
    _items = new SortedList(null, this.constructor._itemCompareFunc);
    _rebuildingItemList = false;

    nameChangedEvent = new EventEmitter(this);
    idChangedEvent = new EventEmitter(this);
    priceChangedEvent = new EventEmitter(this);
    itemsChangedEvent = new EventEmitter(this);
    quotationReferenceChangedEvent = new EventEmitter(this);
    quotationStateChangedEvent = new EventEmitter(this);
    deletionStateChangedEvent = new EventEmitter(this);
    commentChangedEvent = new EventEmitter(this);

    constructor(id, name, comment, configurations, quotationLines) {
        super();

        if (id != null) { this._id = id; }
        if (name != null) { this._name = name; }
        if (comment != null) { this._comment = comment; }
        if (configurations instanceof Array) { this.configurations = configurations; }
        if (quotationLines instanceof Array) { this.quotationLines = quotationLines; }
        
        this._addItemSubscription = this._items.addItemEvent.subscribe(this.onItemAdded.bind(this));
        this._removeItemSubscription = this._items.removeItemEvent.subscribe(this.onItemRemoved.bind(this));
    }

    static get CodingKeys() {
        return {
            id: "sl_No",
            name: "sl_Name",
            comment: "sl_Remark",
            quotationReference: "sl_q_No",
            quotationDocumentId: "document",
            quotationState: "q_State",
            quotationPrice: "q_Price",
            isBeingRevised: "q_CustomerIsRevisingRequest",
            configurations: [{ class: Configuration, property: "contents" }],
            quotationLines: [{ class: QuotationLine, property: "lines" }],
            isDeleted: "sl_Trashed"
        }
    }

    get isBeingRevised() {
        return this._isBeingRevised;
    }

    set isBeingRevised(newValue) {
        this._isBeingRevised = newValue;
        this.quotationStateChangedEvent.notify(this.quotationState);
    }

    get quotationPrice() {
        return this._quotationPrice;
    }

    set quotationPrice(newValue) {
        this._quotationPrice = newValue;
    }

    get urlId() {
        return EncodeUrlId(this.id, null);
    }

    get id() {
        return this._id;
    }

    set id(newId) {
        this._id = newId;
        this.idChangedEvent.notify(this.id);
    }

    get name() {
        return this._name;
    }

    get quotationTitle() {
        var title = "";
        if (this.quotationReference > 0) {
            //title += `&nbsp;-&nbsp;${"project.quotation-number".localized.fillPlaceholders(this.quotationReference)}`;
            title += `${"project.quotation-number".localized.fillPlaceholders(this.quotationReference)}`;
        }

        return title;
    }

    set name(newName) {
        this._name = newName;
        this.nameChangedEvent.notify(this.name);
    }

    get comment() {
        return this._comment;
    }

    set comment(newComment) {
        this._comment = newComment;
        this.commentChangedEvent.notify(this.comment);
    }

    _rebuildItemList() {
        this._rebuildingItemList = true;

        this._items.clear();
        
        for (let configuration of this.configurations) {
            this.items.push(configuration);
        }

        for (let quotationLine of this.quotationLines) {
            this.items.push(quotationLine);
        }

        this._rebuildingItemList = false;
    }

    get items() {
        return this._items;
    }

    get isEmpty() {
        return this.items.length <= 0;
    }

    get configurations() {
        return this._configurations;
    }

    set configurations(newConfigurations) {
        this._configurations = newConfigurations != null ? newConfigurations : [];
        this._rebuildItemList();
    }

    get quotationLines() {
        return this._quotationLines;
    }

    set quotationLines(newQuotationLines) {
        this._quotationLines = newQuotationLines != null ? newQuotationLines : [];
        this._rebuildItemList();
    }

    onItemAdded(list, item) {
        if (!this._rebuildingItemList) {
            //Item was added through code rather than rebuilding/codable
            Logger.debug("[Project] Adding item to internal list");
            if (item instanceof Configuration) {
                this.configurations.push(item);
            } else if (item instanceof QuotationLine) {
                this.quotationLines.push(item);
            }
        }

        item.project = this;
        item.projectNameSubscription = item.nameChangedEvent.subscribe(this.onItemNameChanged.bind(this));
        item.projectPriceSubscription = item.priceChangedEvent.subscribe(this.onItemPriceChanged.bind(this));
        this._onItemsChanged();
    }

    onItemRemoved(list, item) {
        if (!this._rebuildingItemList) {
            //Item was removed through code rather than rebuilding/codable
            Logger.debug("[Project] Removing item from internal list");
            var internalList = null;
            if (item instanceof Configuration) {
                internalList = this.configurations;
            } else if (item instanceof QuotationLine) {
                internalList = this.quotationLines;
            }

            var index = internalList.indexOf(item);
            if (index > -1) {
                internalList.splice(index, 1);
            }
        }

        item.project = null;
        item.projectNameSubscription.unsubscribe();
        item.projectPriceSubscription.unsubscribe();
        this._onItemsChanged();
    }

    _onItemsChanged() {
        this.itemsChangedEvent.notify(this.items.items);
        this.priceChangedEvent.notify(this.price, this.formattedPrice);
    }

    onItemNameChanged() {
        this.items.sort();
    }

    onItemPriceChanged() {
        this.priceChangedEvent.notify(this.price, this.formattedPrice);
    }

    get price() {
        if (this.quotationReady) {
            return this.quotationPrice;
        }

        var price = 0;

        for (let item of this.items.items) {
            price += item.totalPrice;
        }

        return price;
    }

    get formattedPrice() {
        if (!this.isDeleted && this.hasActiveQuotation && !this.quotationReady) {
            return "project.status-processing".localized;
        }

        return FormatPrice(this.price);
    }

    get quotationReference() {
        return this._quotationReference;
    }

    set quotationReference(newQuotationReference) {
        if (newQuotationReference === 0) {
            newQuotationReference = null;
        }

        this._quotationReference = newQuotationReference;
        this.quotationReferenceChangedEvent.notify(this.quotationReference, this.hasQuotation);
        this.nameChangedEvent.notify(this.name);

        for (var configuration of this._configurations) {
            configuration.hasQuotation = this.hasActiveQuotation;
        }
    }

    get hasQuotation() {
        return this.quotationReference != null;
    }

    get hasActiveQuotation() {
        return this.hasQuotation && !this.isBeingRevised;
    }

    get quotationDocumentId() {
        return this._quotationDocumentId;
    }

    set quotationDocumentId(newId) {
        this._quotationDocumentId = newId;
    }

    get quotationReady() {
        return this.quotationState != null && this.quotationState > 0;
    }

    get quotationState() {
        if (this.isBeingRevised) {
            return 0;
        }

        return this._quotationState;
    }

    set quotationState(newState) {
        this._quotationState = newState;
        this.quotationStateChangedEvent.notify(this.quotationState);
        this.nameChangedEvent.notify(this.name);
    }

    set isDeleted(newValue) {
        this._deleted = newValue;
        this.deletionStateChangedEvent.notify(this.isDeleted);

        for (let configuration of this._configurations) {
            configuration.isDeleted = this.isDeleted;
        }
    }

    get isActive() {
        return !this.isDeleted && !this.hasActiveQuotation;
    }

    get isDeleted() {
        return this._deleted;
    }

    get canBeDeleted() {
        return !this.isDeleted;
    }

    get canAddConfiguration() {
        return this.isActive;
    }

    get canBeRenamed() {
        return !this.isDeleted;
    }

    get canRequestQuotation() {
        return this.isActive && !this.isEmpty;
    }

    get canUpdateComment() {
        return this.isActive;
    }
}