import { PageView as VbfPageView } from 'van-beek-framework/abstract';

export default class PageView extends VbfPageView {
    _populate() {
        this.$pageHeader = this._elem.find(".configurator-page-header");
        this.$pagePrimaryTitle = this.$pageHeader.find(".title .primary");
        this.$pageSecondaryTitle = this.$pageHeader.find(".title .secondary");
        this.$pageActions = this.$pageHeader.find(".actions");
    }

    subscribeToUser(user) {
        this._userNameSubscription = user.nameChangedEvent.subscribe(this._onUserNameChanged.bind(this));
        this._userLogoSubscription = user.companyLogoChangedEvent.subscribe(this._onUserLogoChanged.bind(this));
    }

    unsubscribeFromUser() {
        this._userNameSubscription.unsubscribe();
        this._userLogoSubscription.unsubscribe();
    }
}