import { ProjectItem } from 'entities/project-item';
import { Codable } from 'van-beek-framework/abstract';
import { MixinBuilder as mix } from 'van-beek-framework/core';
import { EventEmitter } from 'van-beek-framework/events';

export default class QuotationLine extends mix(Codable).with(ProjectItem) {

    static get CodingKeys() {
        return {
            id: "ol_No",
            name: "ol_Description",
            comment: "orc_Remark",
            additionalSpecifications: "ol_AdditionalSpecifications",
            price: "ol_Price",
            quantity: "ol_Number",
            modelDocumentId: "ol_doc_No",
            modelDocumentOutdated: "ol_DesignOutdated",
            canExecute: "ol_CanExecute"
        }
    }

    quotationStateChangedEvent = new EventEmitter(this);

    get project() {
        return super.project;
    }

    set project(newProject) {
        if (this.project != null) {
            this.quotationStateSubscription.unsubscribe();
        }

        super.project = newProject;

        if (this.project != null) {
            this.quotationStateSubscription = this.project.quotationStateChangedEvent.subscribe(this.onQuotationStateChanged.bind(this));
            this.onQuotationStateChanged();
        }
    }

    onQuotationStateChanged() {
        this.quotationStateChangedEvent.notify();
    }

    get quotationState() {
        return this.project == null ? null : this.project.quotationState;
    }

    get quotationReady() {
        return this.project == null ? false : this.project.quotationReady;
    }

    dataEquals(json) {
        return json != null &&
        (
            (json[this.constructor.CodingKeys.id] != null && this.id === json[this.constructor.CodingKeys.id])
        );
    }
}