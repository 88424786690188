export default class ProjectItemSocketManager {
    _socket = null;
    _listeners = {};

    constructor(configuratorManager, socket) {
        this._socket = socket;
        this._bindItemEvents(configuratorManager);
        this._bindExistingItems(configuratorManager);
    }

    _bindItemEvents(manager) {
        manager.itemAddedEvent.subscribe(this._onItemAdded.bind(this));
        manager.itemRemovedEvent.subscribe(this._onItemRemoved.bind(this));
    }

    _bindExistingItems(manager) {
        for (let item of manager.items) {
            this._onItemAdded(manager, item);
        }
    }

    _onItemAdded(_, item) {
        if (item.uuid == null || this._listeners[item.uuid] != null) {
            return;
        }

        let listener = this._socket.on('finishedModel').in(item.uuid.toLowerCase()).then(this._handleItemModelUpdate.bind(this, item));
        this._listeners[item.uuid] = listener;
    }

    _onItemRemoved(_, item) {
        if (item.uuid == null || this._listeners[item.uuid] == null) {
            return;
        }

        this._listeners[item.uuid].destroy();
        delete this._listeners[item.uuid];
    }

    _handleItemModelUpdate(item, data) {
        // Force item to be executable and the model to be up-to-date,
        // because items should not receive model updates unless the item is valid and the model is up-to-date.
        // (In other words: we trust the server here)
        item.canExecute = true;
        item.modelDocumentOutdated = false;

        // And then set model ID
        item.modelDocumentId = data.document;
    }
}