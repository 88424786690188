import BaseController from 'abstract/BaseController';
import { PopupButton, PopupIcon, LoadingPopup, ConfirmPopup, ErrorPopup, AlertPopup } from 'van-beek-framework/components/ui/popups';
import { CadViewer } from 'components/cad-viewer';
import { PionusDocumentCache, PionusDocument } from 'van-beek-framework/networking';
import { DocumentNotFoundException } from 'van-beek-framework-web/javascript/networking/PionusDocumentCache';

export default class ProjectItemController extends BaseController {
    _cadViewer = new CadViewer();

    subscribeTo(view) {
        view.truncateSubscription = view.commentTruncateButtonEvent.subscribe(this.onTruncate.bind(this));
        view.showModelSubscription = view.showModelButtonEvent.subscribe(this.onShowModel.bind(this));
    }

    unsubscribeFrom(view) {
        view.truncateSubscription.unsubscribe();
        view.showModelSubscription.unsubscribe();
    }

    onTruncate(view) {
        view.setTruncation();
    }

    onShowModel(view, modelDocumentId, showModelOutdatedWarning, modelGenerated) {
        if (!modelGenerated) { //Model is not (being) generated because of an issue in the configuration
            return;
        }

        if (modelDocumentId == null || modelDocumentId <= 0) {
            //Show model does not exist error (still generating)
            AlertPopup.show({
                title: "item.action-show-model.popup-generating.title".localized,
                message: "item.action-show-model.popup-generating.message".localized,
                icon: new PopupIcon("cogs", "#EA2027"),
                confirmButton: PopupButton.neutral("item.action-show-model.popup-generating.button-confirm".localized)
            }).catch(() => {});

            return;
        }

        if (showModelOutdatedWarning) {
            ConfirmPopup.show({
                title: "item.action-show-model.popup-outdated.title".localized,
                message: "item.action-show-model.popup-outdated.message".localized,
                icon: new PopupIcon("cogs", "#F79F1F"),
                confirmButton: PopupButton.confirm("item.action-show-model.popup-outdated.button-confirm".localized)
            }).then(() => {
                this.onShowModel(view, modelDocumentId, false, modelGenerated);
            }).catch(() => {});

            return;
        }

        LoadingPopup.show();

        (async() => {
            try {
                let data = await PionusDocumentCache.shared.get(modelDocumentId, PionusDocument.html);
                this._cadViewer.show(data, view.name);
            } catch(e) {
                if (e instanceof DocumentNotFoundException) {
                    ErrorPopup.show({
                        message: "item.action-show-model.popup-not-found.message".localized
                    });
                } else {
                    ErrorPopup.show({
                        message: "item.action-show-model.popup-error.message".localized
                    });
                }
            }
            
            LoadingPopup.hide();
        })();
    }
}