import { Codable } from 'van-beek-framework/abstract';
import { EventEmitter } from 'van-beek-framework/events';
import { EncodeUrlId } from 'van-beek-framework/core/Utilities';
import { ProjectItem } from 'entities/project-item';
import { MixinBuilder as mix } from 'van-beek-framework/core';

export default class Configuration extends mix(Codable).with(ProjectItem) {

    uuidChangedEvent = new EventEmitter(this);
    hasErrorChangedEvent = new EventEmitter(this);
    hasWarningChangedEvent = new EventEmitter(this);
    activeStateChangedEvent = new EventEmitter(this);

    deletionStateChangedEvent = new EventEmitter(this);
    quotationStateChangedEvent = new EventEmitter(this);

    _uuid = null;
    _hasError = false;
    _hasWarning = false;
    _communicationCounter = 0;

    _isDeleted = false;
    _hasQuotation = false;
    _active = false;

    constructor(id, uuid, name, price, hasError, hasWarning, project) {
        super(id, name, price, null, project);

        this._uuid = uuid || null;
        this._hasError = hasError || false;
        this._hasWarning = hasWarning || false;
    }

    get communicationCounter() {
        return this._communicationCounter;
    }

    set communicationCounter(newValue) {
        this._communicationCounter = newValue;
    }

    get isActive() {
        return this._active;
    }

    set isActive(isActive) {
        this._active = isActive;
        this.activeStateChangedEvent.notify(this.isActive);
    }

    get urlId() {
        return EncodeUrlId(this.project != null ? this.project.id : null, this.uuid);
    }

    get uuid() {
        return this._uuid;
    }

    set uuid(newUuid) {
        this._uuid = newUuid;
        this.uuidChangedEvent.notify(this.uuid);
    }

    get hasError() {
        return this._hasError;
    }

    set hasError(newValue) {
        this._hasError = newValue || false;
        this.hasErrorChangedEvent.notify(this.hasError);
    }

    get hasWarning() {
        return this._hasWarning;
    }

    set hasWarning(newValue) {
        this._hasWarning = newValue || false;
        this.hasWarningChangedEvent.notify(this.hasWarning);
    }

    get isDeleted() {
        return this._isDeleted;
    }

    /*get hasQuotation() {
        return this._hasQuotation;
    }*/

    set isDeleted(deleted) {
        this._isDeleted = deleted;
        this.deletionStateChangedEvent.notify(this.isDeleted);
    }

    /*set hasQuotation(quotation) {
        this._hasQuotation = quotation;
        this.quotationStateChangedEvent.notify(this.hasQuotation);
    }*/

    /*get isReadOnly() {
        return this.hasQuotation || this.isDeleted;
    }*/

    set project(newProject) {
        super.project = newProject;

        if (this.project != null) {
            this.isDeleted = this.project.isDeleted;
            this.hasQuotation = this.project.hasActiveQuotation;
        }
    }

    get project() {
        return super.project;
    }

    static get CodingKeys() {
        return {
            uuid: "orc_ConfigurationID",
            id: "orc_No",
            name: "orc_Name",
            comment: "orc_Remark",
            additionalSpecifications: "orc_AdditionalSpecifications",
            price: "orc_PriceSale",
            hasError: "orc_HasError",
            hasWarning: "orc_HasWarning",
            quantity: "orc_Quantity",
            communicationCounter: "orc_CommunicationCounter",
            modelDocumentId: "orc_doc_No",
            modelDocumentOutdated: "orc_DesignOutdated",
            canExecute: "orc_CanExecute"
        }
    }

    dataEquals(json) {
        return json != null &&
        (
            (json[this.constructor.CodingKeys.id] != null && this.id === json[this.constructor.CodingKeys.id]) ||
            (json[this.constructor.CodingKeys.uuid] != null && this.uuid === json[this.constructor.CodingKeys.uuid])
        );
    }
}