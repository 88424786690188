import "css/ConfigurationView.scss";

import { ProjectItemView } from 'entities/project-item';
import { EventEmitter } from 'van-beek-framework/events';

export default class ConfigurationView extends ProjectItemView {

    renameButtonEvent = new EventEmitter(this);
    editButtonEvent = new EventEmitter(this);
    deleteButtonEvent = new EventEmitter(this);
    duplicateButtonEvent = new EventEmitter(this);
    commentButtonEvent = new EventEmitter(this);
    menuButtonEvent = new EventEmitter(this);
    amountInputEvent = new EventEmitter(this);
    permanentDeleteEvent = new EventEmitter(this);

    _uuid = null;
    _urlId = null;
    _isActive = false;

    get uuid() {
        return this._uuid;
    }

    get urlId() {
        return this._urlId;
    }

    get isActive() {
        return this._isActive;
    }

    _populate() {
        super._populate();

        this.$renameAction = $(`<a href="javascript: void(0);" class="configuration-basket__configuration__action" data-action="rename"><i class="fa fa-fw fa-pencil-alt"></i><div>${"item.action-rename.button".localized}</div></a>`);
        this.$commentAction = $(`<a href="javascript: void(0);" class="configuration-basket__configuration__action" data-action="comment"><i class="fa fa-fw fa-comment-alt"></i><div>${"item.action-comment.button".localized}</div></a>`);
        this.$editAction = $(`<a href="javascript: void(0);" class="configuration-basket__configuration__action" data-action="edit"><i class="fa fa-fw fa-edit"></i><div>${"item.action-edit".localized}</div></a>`);
        this.$deleteAction = $(`<a href="javascript: void(0);" class="configuration-basket__configuration__action" data-action="delete"><i class="fa fa-fw fa-trash"></i><div>${"item.action-delete.button".localized}</div></a>`);
        this.$duplicateAction = $(`<a href="javascript: void(0);" class="configuration-basket__configuration__action" data-action="duplicate"><i class="fa fa-fw fa-copy"></i><div>${"item.action-duplicate.button".localized}</div></a>`);
        this.$menuAction = $(`<a href="javascript: void(0);" class="configuration-basket__configuration__action" data-action="menu"><i class="fa fa-fw fa-bars"></i><div>${"item.action-menu-more".localized}</div></a>`);

        this._actionElems =  [this.$renameAction, this.$commentAction, this.$editAction, this.$deleteAction, this.$duplicateAction, this.$menuAction];
        this._menuActionElems = [];

        this.$buttonWrapper = $('<div class="configuration-basket__configuration__buttons"></div>');
        this.$renameButton = $(`<a href="javascript: void(0);" class="configuration-basket__configuration__button" data-action="rename">${"item.action-rename.button".localized}</a>`);
        this.$editButton = $(`<a href="javascript: void(0);" class="configuration-basket__configuration__button" data-action="edit">${"item.action-edit".localized}</a>`);
        this.$deleteButton = $(`<a href="javascript: void(0);" class="configuration-basket__configuration__button" data-action="delete">${"item.action-delete.button".localized}</a>`);
        this.$duplicateButton = $(`<a href="javascript: void(0);" class="configuration-basket__configuration__button" data-action="duplicate">${"item.action-duplicate.button".localized}</a>`);
        this.$buttonSpacer = $('<span class="configuration-basket__configuration__button-spacer">&nbsp;-&nbsp;</span>');
        this.$dualButtonWrapper = $('<div class="configuration-basket__configuration__buttons--dual"></div>');

        this._buttonElems = [this.$renameButton, this.$editButton, this.$deleteButton, this.$duplicateButton];

        this.$amountInputSubtractButton = $('<div class="configuration-basket__configuration__amount-button" data-action="subtract"><i class="fas fa-minus"></i></div>');
        this.$amountInputAddButton = $('<div class="configuration-basket__configuration__amount-button" data-action="add"><i class="fas fa-plus"></i></div>');
        this.$amountInput = $('<input type="number" min="1" max="99" value="1" />');

        this.$amountInputWrapper.append(this.$amountInputSubtractButton);
        this.$amountInputWrapper.append(this.$amountInput);
        this.$amountInputWrapper.append(this.$amountInputAddButton);

        this.$iconWrapper = $('<div class="configuration-basket__configuration__icons"></div>');
        this.$warningIcon = $('<i class="configuration-basket__configuration__icon warning fas fa-exclamation-triangle"></i>');
        this.$errorIcon = $('<i class="configuration-basket__configuration__icon error fas fa-times-circle"></i>');

        this.$iconWrapper.append(this.$warningIcon);
        this.$iconWrapper.append(this.$errorIcon);

        this.$propertyWrapper.append(this.$iconWrapper);
        this.$textWrapper.append(this.$iconWrapper.clone());

        // So the icons in both places are affected
        this.$iconWrapper = this.$elem.find(".configuration-basket__configuration__icons");
        this.$warningIcon = this.$iconWrapper.find(".configuration-basket__configuration__icon.warning");
        this.$errorIcon = this.$iconWrapper.find(".configuration-basket__configuration__icon.error");
    }

    subscribeTo(configuration) {
        super.subscribeTo(configuration);

        this._uuidSubscription = configuration.uuidChangedEvent.subscribe(this.onUuidChanged.bind(this));
        this._hasErrorSubscription = configuration.hasErrorChangedEvent.subscribe(this.onHasErrorChanged.bind(this));
        this._hasWarningSubscription = configuration.hasWarningChangedEvent.subscribe(this.onHasWarningChanged.bind(this));
        this._deletionStateSubscription = configuration.deletionStateChangedEvent.subscribe(this.updateActions.bind(this));
        this._activeStateSubscription = configuration.activeStateChangedEvent.subscribe(this.onActiveStateChanged.bind(this));

        this.onUuidChanged(configuration);
        this.onHasErrorChanged(configuration);
        this.onHasWarningChanged(configuration);
        this.updateActions(configuration);
        this.onActiveStateChanged(configuration);
    }

    unsubscribeFrom(configuration) {
        super.unsubscribeFrom(configuration);

        this._uuidSubscription.unsubscribe();
        this._hasErrorSubscription.unsubscribe();
        this._hasWarningSubscription.unsubscribe();
        this._deletionStateSubscription.unsubscribe();
        this._activeStateSubscription.unsubscribe();
    }

    _setupEvents() {
        super._setupEvents();

        this.$renameAction.on("click touch", (e) => {
            e.stopPropagation();
            this.renameButtonEvent.notify(this.id, this.uuid, this.name);
        });

        this.$editAction.on("click touch", (e) => {
            e.stopPropagation();
            this.editButtonEvent.notify(this.urlId);
        });

        this.$deleteAction.on("click touch", (e) => {
            e.stopPropagation();
            this.deleteButtonEvent.notify(this.id, this.name);
        });

        this.$duplicateAction.on("click touch", (e) => {
            e.stopPropagation();
            this.duplicateButtonEvent.notify(this.id, this.uuid, this.name);
        });

        this.$commentAction.on("click touch", (e) => {
            e.stopPropagation();
            this.commentButtonEvent.notify(this.id, this.uuid, this._comment);
        });

        this.$menuAction.on("click", (e) => {
            e.stopPropagation();
            this.menuButtonEvent.notify(this.$menuAction, this._menuActionElems);
        });

        this.$amountInputSubtractButton.on("click", (e) => {
            e.stopPropagation();
            this.amountInputEvent.notify(this._quantity - 1);
        });

        this.$amountInputAddButton.on("click", (e) => {
            e.stopPropagation();
            this.amountInputEvent.notify(this._quantity + 1);
        });

        this.$amountInput.on("input", (e) => {
            var newValue = this.$amountInput.val();
            var previousAmount = this.$amountInput.attr("data-value");
            var newAmount = parseInt(newValue);
            if (isNaN(newAmount)) {
                if (newValue.length > 0) {
                    this.$amountInput.val(previousAmount);
                }

                return;
            }

            this.amountInputEvent.notify(newAmount);
        }).on("blur", () => {
            if (this.$amountInput.val().length <= 0) {
                var previousAmount = this.$amountInput.attr("data-value");
                this.$amountInput.val(previousAmount);
            }
        }).on("keydown", (e) => {
            if (e.keyCode === 13) {
                this.$amountInput.blur();
            }
        });
    }

    onActiveStateChanged(configuration) {
        if (configuration.isActive) {
            this.$elem.addClass("active");
        } else {
            this.$elem.removeClass("active");
        }

        this._isActive = configuration.isActive;
    }

    onUuidChanged(configuration) {
        this._uuid = configuration.uuid;
        this._urlId = configuration.urlId;
        //this.$editAction.attr("href", "/configurator/" + configuration.urlId);
    }

    onHasErrorChanged(configuration) {
        if (configuration.hasError) {
            this.$errorIcon.addClass("active");
        } else {
            this.$errorIcon.removeClass("active");
        }
    }

    onHasWarningChanged(configuration) {
        if (configuration.hasWarning) {
            this.$warningIcon.addClass("active");
        } else {
            this.$warningIcon.removeClass("active");
        }
    }

    onQuantityChanged(configuration) {
        super.onQuantityChanged(configuration);

        this.$amountInput.attr("data-value", configuration.quantity);
        this.$amountInput.attr("placeholder", configuration.quantity);

        if (this.$amountInput.val().length > 0) {
            this.$amountInput.val(configuration.quantity);
        }
    }

    updateActions(configuration) {
        this.$editAction.find("i").removeClass("fa-edit fa-eye").addClass(configuration.isDeleted ? "fa-eye" : "fa-edit");
        this.$editAction.find("div").text(configuration.isDeleted ? "item.action-view".localized : "item.action-edit".localized);

        super.updateActions(configuration);
    }

    _getActiveActions(configuration) {
        let actions = [];
        this._menuActionElems = [];

        if (!configuration.isDeleted) {
            actions.push(this.$editAction);
            actions.push(this.$menuAction);
            this._menuActionElems.push(this.$commentAction, this.$renameAction, this.$duplicateAction, this.$deleteAction);
        }

        //Also append default actions to menu
        this._menuActionElems = this._menuActionElems.concat(super._getActiveActions(configuration));

        //Ensure delete action is last
        let deleteIndex = this._menuActionElems.indexOf(this.$deleteAction);
        if (deleteIndex >= 0) {
            this._menuActionElems.push(this._menuActionElems.splice(deleteIndex, 1)[0]);
        }

        return actions;
    }
}